const state = {
    form: {},
    reward_id: '',
    type : '',
    employee_reward_id : ''
}

const mutations = {
    SET_FORM(state, payload) {
        state.form = payload
    },
    SET_REWARD_ID(state, payload) {
        state.reward_id = payload
    },
    SET_TYPE(state, payload) {
        state.type = payload
    },
    SET_EMPLOYEE_REWARD_ID(state, payload) {
        state.employee_reward_id = payload
    },

}

const getters = {
    getForm(state) {
        if (state.form) {
            return ruleRequire(state.form.mySchema)
        }
    }
}
const required = msg => v => !!v || msg
const requiredNormal = required('Erequired')


function ruleRequire(schema) {
    let item = Object.keys(schema);
    for (let index = 0; index < item.length; index++) {
        schema[item[index]].rules = [requiredNormal];
    }
    return schema
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};