const state = {
  dataSaveCampaign: {
    schema: null,
    name: "",
  },
  editedSchema: -1,
};

const mutations = {
  SET_SCHEMA(state, payload) {
    if (payload.id > -1) {
      state.dataSaveCampaign = Object.assign(
        {},
        { ...payload, schema: JSON.parse(payload.schema) }
      );
    }
    state.editedSchema = payload.id;
  },
};

const getters = {
  getSchema: (state) => {
    if (state.editedSchema > -1) {
      return state.dataSaveCampaign;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
