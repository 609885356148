<template>
  <v-app
    id="materialpro"
    :class="
      `${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`
    "
  >
    <router-view v-show="!overlayLoading" />
    <div class="text-center">
      <v-overlay :z-index="zIndex" :value="overlayLoading" opacity="0.9">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <h2 class="mt-5">Please Wait...</h2>
      </v-overlay>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    zIndex: 205,
  }),
  computed: {
    overlayLoading: {
      set() {
        this.$store.commit("defaultSystem/SET_LOADING", false);
      },
      get() {
        return this.$store.getters["defaultSystem/getLoading"];
      },
    },
  },
  watch: {
    overlayLoading: {
      handler() {
        this.overlayLoading;
      },
      deep: true,
    },
  },
};
</script>
<style>
/* .main {
  max-height: 2000px;
}
.menu-text {
  z-index: 30;
}
.hamberger-menu {
  z-index: 40;
}
.display-coin {
  position: absolute;
  z-index: 50;
}
.v-list--two-line .v-list-item,
.v-list-item--two-line {
  min-height: 100px;
} */

@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@400;500;600&display=swap");

body {
  font-family: "Poppins", "Athiti" !important;
}
.card-css {
    --bs-card-spacer-y: 1rem !important;
    --bs-card-spacer-x: 1rem !important;
    --bs-card-title-spacer-y: 0.5rem !important;
    --bs-card-border-width: 0 !important;
    --bs-card-border-color: rgba(0,0,0,.125) !important;
    --bs-card-border-radius: 1rem !important;
    --bs-card-box-shadow: 0 0 2rem 0rgba(136,152,170,.15) !important;
    --bs-card-inner-border-radius: 1rem !important;
    --bs-card-cap-padding-y: 0.5rem !important;
    --bs-card-cap-padding-x: 1rem !important;
    --bs-card-cap-bg: #fff !important;
    --bs-card-bg: #fff !important;
    --bs-card-img-overlay-padding: 1rem !important;
    --bs-card-group-margin: 0.75rem !important;
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    min-width: 0 !important;
    height: var(--bs-card-height) !important;
    word-wrap: break-word !important;
    background-color: var(--bs-card-bg) !important;
    background-clip: border-box !important;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color) !important;
    border-radius: var(--bs-card-border-radius) !important;
}
</style>
